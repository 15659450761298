import logo from './logo192.png';
import { useEffect, useState } from "react";
import {
  connectWallet,
  getCurrentWalletConnected,
  mint,
  setGameStatusContract
} from "./functions.js"; 
//import importWalletButton from "./Importwalletbutton.js"
import About from "./About.js"

///////////////////////////////////////START CONNECT WALLET [do not change]/////////////////////////////////////
const Minter = (props) => {
  const [walletAddress, setWallet] = useState("");
  const [status, setStatus] = useState("");
  const [info, setInfo] = useState(""); 
  useEffect(async () => {
    const { address, status } = await getCurrentWalletConnected();

    setWallet(address);
    setStatus(status);

    addWalletListener();
  }, []);

  function addWalletListener() {
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", (accounts) => {
        if (accounts.length > 0) {
          setWallet(accounts[0]);
          setStatus("Ready.");
        } else {
          setWallet("");
          setStatus("🦊 Connect to Metamask using the top right button.");
        }
      });
    } else {
      setStatus(
        <p>
          {" "}
          🦊{" "}
          <a target="_blank" href={`https://metamask.io/download.html`}>
            You must install Metamask, a virtual Ethereum wallet, in your
            browser.
          </a>
        </p>
      );
    }
  }

  const connectWalletPressed = async () => {
    const walletResponse = await connectWallet();
    setStatus(walletResponse.status);
    setWallet(walletResponse.address);
  };
///////////////////////////////////////END CONNECT WALLET/////////////////////////////////////

//START 

  //const [fireStatus, setFire] = useState("");
  //const [fireGameStatus, fireSetGameStatus] = useState("");

  const fireme = async () => {                           
    setGameStatusContract();
    //console.log("Ask dev to reenable button")
  };
  const link = "";
  const linkcontract = "";
  return (
    
    <div className="Minter" >

      <div className="InsideMinter">
        <button id="walletButton" onClick={connectWalletPressed}>
          {walletAddress.length > 0 ? (
            "Connected: " +
            String(walletAddress).substring(0, 6) +
            "..." +
            String(walletAddress).substring(35, 38)
          ) : (
            <span>Connect Wallet</span>
          )}
        </button>
        <br></br>
        <h1 id="title">{/*Divi's ... Eternal... Infinity...Possibility..Destiny */}Divi's Eternal Cubes  </h1>
        {/*<iframe id="nftmain" src="https://ipfs.io/ipfs/?filename=demo_0.html"> </iframe> */} 
        <br /> 

        <img src={logo} className="App-logo" alt="logo" id="supermint"/> 
        <h1> COMING SOON </h1>  
        {/*<button id="buttonspecial" onClick={fireme}> M I N T </button> */} 
 {/*
        <p id="status" > 
        <br />
        <a id="link" href={link}> Opensea</a> <br /> <br /> 
        <a id="link" href={linkcontract}> Etherscan</a>
        <br /><br />
        <br /><br />
        
        <span> make certain you are a real person before minting these cubes </span> <br /> 

          <br /> 
        </p> */} 
        <div id="community">
                <h2> Community </h2>
                
                <br /> <br /> <br /> 
                
                <a href="https://discord.gg/dYApub3mMf" id="statbutton" className="navlinkexternal" style= {{
                }}>  Join the Cubes Discord!  </a>
                
                <br /> <br /><br /> <br />
                <a href="https://twitter.com/0xdivi?ref_src=twsrc%5Etfw" id="statbutton" className="navlinkexternal" >@0xdivi on twitter </a>
                <br /> <br /> <br /> <br /> <br /> <br /> 
        </div>        
        <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> 
        {/* <About /> */}
      </div>
    </div>
  );
};

export default Minter;
